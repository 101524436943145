<div *ngIf="this.messageElements.length > 1">
  <ng-container *ngFor="let messageElement of messageElements">

       <span *ngIf="this.isSentence(messageElement)">
        {{ messageElement.sentence }}
       </span>

    <span *ngIf="this.isSentenceEnding(messageElement)"
          class="more"
          style="vertical-align:super;"
          (click)="this.loadPrediction(messageElement.sentenceIndex)"
    >
        {{ messageElement.sentenceIndex }}
       </span>
  </ng-container>

  <div style="height:10px"></div>

  <span *ngIf="!this.lastPredictionWasEmpty"
        class="more"
        (click)="this.requestPrediction(this.messageElements.length)"
  >
    >>
  </span>

  <div class="userActions">

    <button class="ms-Button ms-Button--primary"
            (click)="this.loadPrediction(this.messageElements.length)"
    >
      <span class="ms-Button-label">{{'INSERT-TEXT-BUTTON' | translate}}</span>
    </button>

  </div>

</div>

<div *ngIf="this.messageElements.length <= 1">
  {{ 'PREDICTION-NOT-AVAILABLE' | translate }}
</div>
