import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {htmlToText} from 'html-to-text';

@Component({
  selector: 'app-salutation-modal',
  templateUrl: './salutation-modal.component.html',
  styleUrls: ['./salutation-modal.component.css']
})
export class SalutationModalComponent implements OnInit {
  @Input() salutation: string;
  @Output() changeSelectedSalutationEvent: EventEmitter<string> = new EventEmitter<string>();

  salutationText: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.salutationText = htmlToText(this.salutation);
  }

  emitEvent(salutation: string){
    this.changeSelectedSalutationEvent.emit(salutation);
  }

}
