import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ExecuteUserActionData} from 'src/app/services/mailytica-http/ExecuteUserActionData';
import {AvailableUserAction} from 'src/app/services/mailytica-http/MailyticaSmartResponse';
import {Metadata} from 'src/app/services/mailytica-http/MetaData';
import {MetadataModalComponent} from '../metadata-modal/metadata-modal.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {OfficeRoamingSettingsService} from 'src/app/services/office-roaming-settings/office-roaming-settings.service';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.css']
})
export class UserActionsComponent {

  @Input() availableUserActions: AvailableUserAction[];
  @Input() metaData: Metadata[];

  @Output() sendUserActionRequest: EventEmitter<ExecuteUserActionData> = new EventEmitter<ExecuteUserActionData>();

  public showModal = false;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private officeService: OfficeRoamingSettingsService
  ) {

    translateService.addLangs(['en', 'de']);
    translateService.setDefaultLang('de');
    translateService.use(officeService.getLanguage());
  }

  public checkUserActionParameter(availableUserAction: AvailableUserAction) {

    const allNecessaryMetadataSet: boolean = availableUserAction.allNecessaryMetadataSet(this.metaData);

    if (!allNecessaryMetadataSet) {

      this.showModal = false;
      this.sendRequest(this.metaData, availableUserAction);

    } else {

      const dialogRef = this.dialog.open(MetadataModalComponent, {
        data: {requiredMetadata: availableUserAction.getRequiredMetadata(this.metaData)}
      });

      dialogRef.afterClosed().subscribe(data => {

        this.sendRequest(data, availableUserAction);
      });
    }
  }

  public sendRequest(metadata: Metadata[], availableUserAction: AvailableUserAction) {

    const filteredMetadata = metadata.filter(md => availableUserAction.actionInputFields.includes(md.metadataFieldId));

    this.sendUserActionRequest.emit(new ExecuteUserActionData(availableUserAction.actionId, filteredMetadata));
  }
}
