<div *ngIf="this.smartResponseProposals.length != 0">
    <div class="ms-font-l">{{'E-MAIL-TEMPLATE' | translate}}</div>

    <ul class="nav nav-tabs">
        <li class="nav-item dropdown" *ngFor="let proposal of this.sortedSmartResponseProposals">
          <a class="nav-link dropdown-toggle active" data-toggle="dropdown" *ngIf="proposal.smartResponseProposals.length > 1 && proposal.topicLabel != this.selectedSmartResponseProposal.topicLabel">{{proposal.topicLabel}}</a>
          <a class="nav-link dropdown-toggle active selectedTopic" data-toggle="dropdown" *ngIf="proposal.smartResponseProposals.length > 1 && proposal.topicLabel == this.selectedSmartResponseProposal.topicLabel">{{proposal.topicLabel}}</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" *ngFor="let template of proposal.smartResponseProposals" (click)="this.changeSelectedSmartResponseProposal(template)">{{template.label}}</a>
          </div>

          <a class="nav-link active" *ngIf="proposal.smartResponseProposals.length == 1 && proposal.topicLabel != this.selectedSmartResponseProposal.topicLabel" (click)="this.changeSelectedSmartResponseProposal(proposal.smartResponseProposals[0])">{{proposal.topicLabel}}</a>

          <a class="nav-link active selectedTopic" *ngIf="proposal.smartResponseProposals.length == 1 && proposal.topicLabel == this.selectedSmartResponseProposal.topicLabel" (click)="this.changeSelectedSmartResponseProposal(proposal.smartResponseProposals[0])">{{proposal.topicLabel}}</a>
        </li>
    </ul>
</div>