<div class="modal fade" id="salutationModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="ms-TextField">
                    <input class="ms-TextField-field" value="{{this.salutationText}}" #inputSalutation>
                </div>
            </div>
            <div class="modal-footer">
                <button class="ms-Button ms-Button--secundary buttonsModalFooter" data-dismiss="modal">
                    <span class="ms-Button-label">{{'CANCEL-BUTTON' | translate}}</span>
                </button>
                <button class="ms-Button ms-Button--primary buttonsModalFooter" data-dismiss="modal"
                    (click)="this.emitEvent(inputSalutation.value)">
                    <span class="ms-Button-label">{{'SAVE-BUTTON' | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</div>