import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MailyticaHttpService } from 'src/app/services/mailytica-http/mailyticaHttp.service';
import { OfficeRoamingSettingsService } from 'src/app/services/office-roaming-settings/office-roaming-settings.service';
import { Error } from '../../mailytica/settings/login/error';
import ISO6391 from 'iso-639-1';
import { CategoryModel } from 'src/app/services/mailytica-http/AvailableScopesHeader';
import { AccountService } from 'src/app/services/account/account.service';
import { LoginRequest } from 'src/app/services/mailytica-http/LoginCredentials';
import * as constants from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MailboxMailyticaAccountMapping } from 'src/app/services/office-roaming-settings/MailboxMailyticaAccountMapping';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit, OnChanges {
  @Input() mailboxMailyticaAccountMapping: MailboxMailyticaAccountMapping;
  @Input() mailyticaAPIUrl: string;
  @Input() accountWhitelisting: string [];
  @Input() authorizationValueConfiguredInManifest: boolean;
  @Input() loginUrl: string;

  @Output() logoutEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() submitLoginEvent: EventEmitter<any> = new EventEmitter<any>();

  public constants = constants;
  public convertIsoCodeToLanguage = AccountService.convertIsoCodeToLanguage;
  public convertLanguageToIsoCode = AccountService.convertLanguageToIsoCode;

  private accessToken: string;  
  public categoryModels: CategoryModel[] = new Array();
  public error: Error = new Error();

  constructor(
    public translateService: TranslateService,
    public mailyticaService: MailyticaHttpService,
    public officeService: OfficeRoamingSettingsService,
    public accountService: AccountService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.translateService.addLangs(['en', 'de']);
    this.translateService.use(this.officeService.getLanguage());
  }

  ngOnChanges(): void {
    this.error = new Error();
  }

  public async login(loginRequest: LoginRequest) {
    let tempCategoryModels: CategoryModel[];
    try{
      this.accessToken = await this.accountService.login(loginRequest, this.mailyticaAPIUrl);
      tempCategoryModels = await this.accountService.getAvailableScopes(this.mailyticaAPIUrl, this.accessToken);
      this.categoryModels = tempCategoryModels.map(categoryModel => CategoryModel.fromJson(categoryModel));
    }
    catch(error){
      if (error.status !== 0) {
        this.error.errorLogIn = error;
      } else {
        this.error.errorAPI = error;
      }
    }
  }

  public async submit(categoryModel: CategoryModel) {
    try{
      let authorizationValue = await this.accountService.submit(this.mailboxMailyticaAccountMapping.mailbox, categoryModel, this.mailyticaAPIUrl, this.accessToken);

      this.submitLoginEvent.emit({categoryModel, authorizationValue});

      let loginMessage = this.translateService.instant("LOGIN-SUCCESSFULL");
      this.openSnackBar(loginMessage);
    } catch(error) {
      this.error.errorAPI = error;
    }
  }
  
  async logout() {
    try{
      this.logoutEvent.emit();
      let logoutMessage = this.translateService.instant("LOGOUT-SUCCESSFULL");
      this.openSnackBar(logoutMessage);
      this.error = new Error();
    } catch(error) {
      this.error.errorAPI = error;
    }
  }

  public changeLanguage(lang: any) {
    this.translateService.use(ISO6391.getCode(lang.target.innerText));
    this.officeService.setLanguage(ISO6391.getCode(lang.target.innerText));
  }

  public openUrl(url: string){
    window.open("https://" + url);
  }

  private openSnackBar(text: string){
    let dismiss = this.translateService.instant("DISMISS");
    this._snackBar.open(text, dismiss,{
      duration: 5000
    });
  }
}
