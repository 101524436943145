<div class="ms-font-l">{{'LOGIN-SETTINGS' | translate}}</div>
<div class="ms-font-l">{{this.mailbox}}</div>

<div *ngIf="this.categoryModels.length === 0">
    <div class="ms-TextField" >
        <label class="ms-Label">{{'USERNAME' | translate}}</label>
        <input [ngClass]="this.error.errorLogIn ? 'ms-TextField-field errorInput' : 'ms-TextField-field'" type="text" [(ngModel)]="this.loginRequest.username" (keyup.enter)="this.clickLoginEvent()">
    </div>

    <div class="ms-TextField">
        <label class="ms-Label">{{'PASSWORD' | translate}}</label>
        <input [ngClass]="this.error.errorLogIn ? 'ms-TextField-field errorInput' : 'ms-TextField-field'" type="password" [(ngModel)]="this.loginRequest.password" (keyup.enter)="this.clickLoginEvent()">
    </div>

    <div class="ms-font-m errorLoginData" *ngIf="this.error.errorLogIn != undefined">{{'ERROR-LOGIN' | translate}}</div>

    <button class="ms-Button ms-Button--primary" (click)="this.clickLoginEvent()" *ngIf="this.categoryModels.length == 0" [disabled]="this.loginRequest.username == '' || this.loginRequest.password == ''">
        <div *ngIf="this.loginEventSend && this.categoryModels.length == 0 && !this.error.errorLogIn && !this.error.errorAPI && !this.error.errorOfficeContext else loginLabel">
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-light" role="status" >
                    <span class="visually-hidden ms-font-l">{{'LOADING-DATA' | translate}}</span>
                </div>
            </div>
        </div>

        <ng-template #loginLabel>
            <span class="ms-Button-label" >{{'LOGIN-BUTTON' | translate}}</span>
        </ng-template>
    </button>

    <div class="error" *ngIf="this.error.errorAPI != undefined">
        <div class="errorHeadline">
            <i class="ms-Icon ms-Icon--ErrorBadge" aria-hidden="true"></i>
            <div class="ms-font-m errorStatus">{{'ERROR' | translate}}: {{this.error.errorAPI.status}}</div>
        </div>
        
        <div class="errorBody">
            <div class="ms-font-s errorText">{{this.constants.name}}{{'ERROR-0' | translate}}</div>
        </div>
    </div>

    <div class="error" *ngIf="this.error.errorOfficeContext != undefined">
        <div class="errorHeadline">
            <i class="ms-Icon ms-Icon--ErrorBadge" aria-hidden="true"></i>
            <div class="ms-font-m errorStatus">{{this.error.errorOfficeContext}}</div>
        </div>
    </div>
</div>

<div *ngIf="this.categoryModels.length !== 0">
    <div class="btn-group btn-block">
        <div class="ms-TextField">
            <label class="ms-Label">{{'CATEGORYMODEL' | translate}}</label>
            <button class="btn btn-default btn-block dropdown-toggle text-left" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{this.selectedCategoryModel.categoryModelLabel}}</button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a *ngFor="let model of this.categoryModels" class="dropdown-item" (click)="this.updateSelectedCategoryModel(model)">{{model.categoryModelLabel}}</a>
            </div>
        </div>
    </div>
    
    <button class="ms-Button ms-Button--primary" (click)="this.clickSubmitEvent()">
        <span class="ms-Button-label">{{'SAVE-BUTTON' | translate}}</span>
    </button>
    
    <div class="error" *ngIf="this.error.errorAPI != undefined">
        <div class="errorHeadline">
            <i class="ms-Icon ms-Icon--ErrorBadge" aria-hidden="true"></i>
            <div class="ms-font-m errorStatus">{{'ERROR' | translate}}: {{this.error.errorAPI.status}}</div>
        </div>
        
        <div class="errorBody">
            <div class="ms-font-s errorText">{{this.constants.name}}{{'ERROR-0' | translate}}</div>
        </div>
    </div>
</div>