import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import * as constants from 'src/environments/environment';
import { AccountService } from 'src/app/services/account/account.service';

@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.css']
})
export class LanguageModalComponent implements OnInit, OnChanges {
  @Input() selectedLanguage: string;
  @Output() changeSelectedLanguageEvent: EventEmitter<string> = new EventEmitter<string>();

  languages: string[] = constants.languages;

  constructor(public settingsService: AccountService) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.selectedLanguage = AccountService.convertIsoCodeToLanguage(this.selectedLanguage);
  }

  changeSelectedLanguage(language: string){
    this.selectedLanguage = language;
  }

  emitEvent(){
    this.changeSelectedLanguageEvent.emit(this.selectedLanguage);
  }
}
