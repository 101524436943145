import { EventEmitter, Injectable } from '@angular/core';
import ISO6391 from 'iso-639-1';

import { LoginRequest } from '../mailytica-http/LoginCredentials';
import { AvailableScopesHeader, CategoryModel } from '../mailytica-http/AvailableScopesHeader';
import { MailyticaHttpService } from '../mailytica-http/mailyticaHttp.service';
import { OfficeRoamingSettingsService } from '../office-roaming-settings/office-roaming-settings.service';
import { MailboxMailyticaAccountMapping } from '../office-roaming-settings/MailboxMailyticaAccountMapping';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    public mailyticaHttpService: MailyticaHttpService,
    public officeRoamingSettingsService: OfficeRoamingSettingsService
  ) {
  }

  public async login(loginRequest: LoginRequest, mailyticaAPIUrl: string): Promise<string> {
    let accessToken: string = "";

    try {
      accessToken = await this.mailyticaHttpService.login(loginRequest, mailyticaAPIUrl)
      // let scopesHeader: AvailableScopesHeader = await this.mailyticaHttpService.getAvailableScopes(mailyticaAPIUrl, accessToken)
      // scopesHeader.categoryModels.map(element => categoryModels.push(element))
    } catch (error) {
      throw error;
    }

    return accessToken;
  }

  public async getAvailableScopes(mailyticaAPIUrl: string, accessToken: string): Promise<CategoryModel[]> {
    let categoryModels: CategoryModel[] = new Array();

    try{
      let scopesHeader: AvailableScopesHeader = await this.mailyticaHttpService.getAvailableScopes(mailyticaAPIUrl, accessToken)
      scopesHeader.categoryModels.map(element => categoryModels.push(element))
    } catch (error) {
      throw error;
    }

    return categoryModels;
  }

  public async submit(mailbox: string, categoryModel: CategoryModel, mailyticaAPIUrl: string, accessToken: string): Promise<string> {
    try {
      let submitResponse = await this.mailyticaHttpService.submit(categoryModel, mailyticaAPIUrl, accessToken);

      await this.officeRoamingSettingsService.setCategoryModel(mailbox, categoryModel);
      await this.officeRoamingSettingsService.setMailboxAuthorizationValueMapping(mailbox, "Bearer " + submitResponse.access_token)

      //this.mailyticaHttpService.setAuthorizationValue(submitResponse.access_token)
      return submitResponse.access_token;
    } catch (error) {
      throw error;
    }
  }

  async logout(mailbox: string){
    try{
      await this.officeRoamingSettingsService.removeMailboxMailyticaAccountMapping(mailbox);
    } catch (error) {
      throw error;
    }
  }

  static convertIsoCodeToLanguage(iso: string): string {
    if(iso == "CZ"){
      return "Czech";
    }
    else{
      return ISO6391.getName(iso.toLowerCase());
    }
  }

  static convertLanguageToIsoCode(lang: string): string {
    if(lang == "Czech"){
      return "CZ";
    }
    else{
      return ISO6391.getCode(lang).toUpperCase();
    }
  }
}
