import {HttpErrorResponse} from '@angular/common/http';
import {Component, NgZone, OnInit} from '@angular/core';
import {MailyticaSmartResponse, SmartResponseProposal} from '../services/mailytica-http/MailyticaSmartResponse';
import {Message} from '../services/mailytica-http/Message';
import {TranslateService} from '@ngx-translate/core';
import {OfficeRoamingSettingsService} from '../services/office-roaming-settings/office-roaming-settings.service';
import {SmartResponseService} from '../services/smartResponse/smart-response.service';
import {MailboxMailyticaAccountMapping} from '../services/office-roaming-settings/MailboxMailyticaAccountMapping';
import {OfficeItemChangedService} from '../services/office-item-changed/office-item-changed.service';
import {Md5} from 'ts-md5';
import {ActivatedRoute} from '@angular/router';
import * as constants from 'src/environments/environment';
import {PredictionConsumption, PredictionMessage} from '../services/mailytica-http/PredictionMessage';
import {NotificationService} from '../services/notification/notification.service';
import {MyUnsubscribeComponent} from '../my-unsubscribe/my-unsubscribe.component';
import {MailyticaHttpService} from '../services/mailytica-http/mailyticaHttp.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-message-compose',
  templateUrl: './message-compose.component.html',
  styleUrls: ['./message-compose.component.css']
})
export class MessageComposeComponent extends MyUnsubscribeComponent implements OnInit {

  public authorizationValue: string = '';
  public mailboxMailyticaAccountMapping: MailboxMailyticaAccountMapping;
  public loadingAnimation: boolean = false;
  public mailyticaSmartResponse: MailyticaSmartResponse;
  public currentlyDisplayedSmartResponseProposal: SmartResponseProposal;
  public classificationLabel: string = 'Standard';

  private initialEmailText: string;

  public httpErrorResponse: HttpErrorResponse;
  public messageError: string;

  private mailyticaAPIUrl: string = 'api.mailytica.ai';
  public constants = constants;

  predictionEmitter: Subject<PredictionMessage> = new Subject<PredictionMessage>();
  predictionConsumptionEmitter: Subject<PredictionConsumption> = new Subject<PredictionConsumption>();
  expandPredictionRequestEmitter: Subject<string> = new Subject<string>();

  constructor(
    public mailyticaHttpService: MailyticaHttpService,
    public notificationService: NotificationService,
    private officeService: OfficeRoamingSettingsService,
    public smartResponseService: SmartResponseService,
    public translate: TranslateService,
    private zone: NgZone,
    private officeItemChangedService: OfficeItemChangedService,
    private activatedRoute: ActivatedRoute,
    private officeRoamingSettingsService: OfficeRoamingSettingsService
  ) {
    super();

    translate.addLangs(['en', 'de']);
    translate.setDefaultLang('de');
    translate.use(officeService.getLanguage());
  }

  ngOnInit(): void {

    this.registerSubscription(
      this.activatedRoute.paramMap.subscribe(paramMap => {

        if (paramMap.get('baseUrl') != null) {
          this.mailyticaAPIUrl = paramMap.get('baseUrl');
          this.mailyticaAPIUrl = 'api.mailytica.dev'; // FIXME
        }
      })
    );

    // get optional configuration from manifest
    this.registerSubscription(
      this.activatedRoute.queryParams
        .subscribe(paramMap => {

          if (paramMap.accessToken != null) {
            this.authorizationValue = 'Bearer ' + paramMap.accessToken;
            this.authorizationValue = 'Bearer eyJraWQiOm51bGwsImFsZyI6IlJTMjU2In0.eyJpc3MiOiJNYWlseXRpY2EiLCJhdWQiOiJNYWlseXRpY2EgQWNjZXNzIFRva2VuIiwiZXhwIjoxNzMwMTI1NzU0LCJqdGkiOiJ3dUxhdmJXU2ZwT2MycThvRkhKSURBIiwiaWF0IjoxNjk4NTg5NzU0LCJuYmYiOjE2OTg1ODk2MzQsInN1YiI6ImxvcmVuei5iZXJuYXVlckBtYWlseXRpY2EuY29tIiwiY2F0ZWdvcnlNb2RlbElkIjoiM2MxMzhmOTgtOTQ2MS00ZTI1LTg0MjktM2RiOWJkZGU2M2QxIiwiY29tcGFueUlkIjoiY2E5MmEzMTctNjIzZS00MmU3LWEyZmQtOWI4M2RlYzAwOGU3In0.QGdJbgi04Ud97fogPqDvp_xSwybDuGvx6GAVC_UOkpcd7qw7oKmKtkdJZZo_WMgbCswd6qegFwT47VluJgZZXtpwnlKJnOkicV6J4xVD-3I8XI3SYxkvZ-4x7gsEiC7jnVuXvOMT24dX9_TId4-DhjHS-jrjZ0DyT4SJ31J0uQwLBHygUQpfx9I4KhV0RQxBm0wuw8gfomOrrzJybhJUViA7JEKpmeBTegLyguVRlATEd-TBgrCSeEkNSxnWNV1s03ZVjjVU4NIQ_eX7ZxsXW5AwNVONAk6k3_t0vyOnqB3lFTveP6zKjxf3dQliuszqHqLbNdRs6OxneMJ_gmNKOA';
          }
        })
    );

    this.officeItemChangedService.itemSenderChanged();

    if (constants.smartAssist) {
      this.officeItemChangedService.itemBodyChanged();
    }

    // reload add-in when emailItem changed
    this.registerSubscription(
      OfficeItemChangedService.mailItem.subscribe(mailboxMailyticaAccountMapping => {

        this.authorizationValue = '';
        this.activatedRoute
          .queryParams
          .subscribe(paramMap => {

            if (paramMap.accessToken != null) {
              this.authorizationValue = 'Bearer ' + paramMap.accessToken;
            }
          });

        this.mailboxMailyticaAccountMapping = mailboxMailyticaAccountMapping;

        this.zone.run(async () => {
          this.initialize();
        });
      })
    );

    // check user input for smartAssist
    if (constants.smartAssist) {

      this.registerSubscription(
        OfficeItemChangedService.itemBody.subscribe(async isWriting => {

          if (this.mailyticaSmartResponse) {

            const inputMessage = this.officeItemChangedService.getInputMessage();

            await this.updatePredictionMessage(inputMessage, true);

            this.updateSelectedSmartResponseProposalForClassification('Smart Assist');
          }
        })
      );
    }

    // check recipients periodical
    this.registerSubscription(
      OfficeItemChangedService.itemSender.subscribe(sender => {

        if (this.authorizationValue !== undefined && this.authorizationValue !== '') {
          this.classifyDraft();
        }
      })
    );

    if (constants.smartAssist) {

      this.registerSubscription(
        this.predictionConsumptionEmitter.subscribe(
          prediction => {
            console.log(prediction.composedHtml());

            Office.context.mailbox.item.body.setSelectedDataAsync(
              prediction.composedHtml(),
              {coercionType: Office.CoercionType.Html}
            );

            this.updatePredictionMessage(prediction.prediction, true);
          },
          error => console.error(error)
        )
      );
    }

    if (constants.smartAssist) {

      this.registerSubscription(
        this.expandPredictionRequestEmitter.subscribe(
          predictionRequest => this.updatePredictionMessage(predictionRequest, false),
          error => console.error(error)
        )
      );
    }

    this.initialize();
  }

  private async initialize() {

    const mailbox = await MailboxMailyticaAccountMapping.getCurrentMailbox();
    const categoryModel = this.officeRoamingSettingsService.getCategoryModel(mailbox);
    this.mailboxMailyticaAccountMapping = new MailboxMailyticaAccountMapping(mailbox, categoryModel);

    // if authorization was not set in manifest --> try to get authorizationValue from roamingSettings;
    if (this.authorizationValue === '') {

      this.authorizationValue = this.officeRoamingSettingsService.getMailboxAuthorizationValueMapping(this.mailboxMailyticaAccountMapping.mailbox.toLowerCase());
    }

    if (this.authorizationValue !== undefined) {

      await this.classifyDraft();

    } else {

      super.registerSubscription(
        this.translate
          .get('EMAIL-DRAFT-NO-ACCESS-TOKEN')
          .subscribe(translation => this.messageError = translation)
      );
    }
  }

  public async classifyDraft() {

    this.loadingAnimation = true;

    if (this.mailboxMailyticaAccountMapping.categoryModel) {

      const message = await Message.getDraft(this.draftId());

      if (message.sender) {
        this.messageError = undefined;
        this.initialEmailText = this.getHashValue(await Message.getBodyText());

        this.smartResponseService.classifyDraft(message, this.mailyticaAPIUrl, this.authorizationValue).subscribe((data) => {
          this.mailyticaSmartResponse = MailyticaSmartResponse.fromJson(data);
          this.classificationLabel = this.mailyticaSmartResponse.classification.label;
          this.updateSelectedSmartResponseProposalForClassification(this.mailyticaSmartResponse.classification.label);
          this.mailyticaSmartResponse.addSmartAssistProposal();

          this.loadingAnimation = false;
        }, error => this.httpErrorResponse = error);
      } else {
        this.loadingAnimation = false;
        this.translate.get('EMAIL-DRAFT-RECIPIENT-NOT-SET').subscribe(translation => this.messageError = translation);
      }
    }
  }

  private draftId(): string {

    const draftId = Office.context.mailbox.item.conversationId;

    if (draftId) {

      return draftId;
    } else {

      return this.generateQuickGuid();
    }
  }

  private generateQuickGuid() { // FIXME

    return Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
  }

  public async updateSelectedTopic(selectedTopic: string) {

    this.loadingAnimation = true;

    const message = await Message.getDraft(this.draftId());

    const subscription = this.smartResponseService
      .updateSelectedTopic(message, selectedTopic, this.mailyticaAPIUrl, this.authorizationValue)
      .subscribe(
        (mailyticaSmartResponse) => {

          this.mailyticaSmartResponse = mailyticaSmartResponse;

          this.classificationLabel = this.mailyticaSmartResponse.classification.label;
          this.mailyticaSmartResponse.addSmartAssistProposal();
          this.updateSelectedSmartResponseProposalForClassification(this.classificationLabel);

          this.loadingAnimation = false;

          this.notificationService.notify('FINISH-MESSAGE-RESPONSE');
        },
        (error) => console.error(error)
      );

    this.registerSubscription(subscription);
  }

  public updateSelectedSmartResponseProposal(selectedSmartResponseProposal: SmartResponseProposal) {
    this.currentlyDisplayedSmartResponseProposal = selectedSmartResponseProposal;
  }

  public updateSelectedSmartResponseProposalForClassification(topicLabel: string) {
    //take first smartResponseProposal with same topic as classification
    this.currentlyDisplayedSmartResponseProposal = this.mailyticaSmartResponse
      .smartResponseProposals
      .find(proposal => proposal.topicLabel === topicLabel);

    //if no smartResponseProposal with same topic as classification was found
    if (this.currentlyDisplayedSmartResponseProposal === undefined) {
      this.currentlyDisplayedSmartResponseProposal = this.mailyticaSmartResponse.smartResponseProposals[0];
    }
  }

  public async updatePredictionMessage(inputMessage: string, resetDisplay: boolean) {

    if (constants.smartAssist) {

      this.loadingAnimation = true;

      const inputWords: string[] = inputMessage.trim().split(' ');

      const lastWrittenWords = inputWords
        .slice(Math.max(0, inputWords.length - 10), inputWords.length) // last 10 words
        .join(' ') + ' ';

      super.registerSubscription(
        this.smartResponseService
          .predictMessage(lastWrittenWords, this.mailyticaAPIUrl, this.authorizationValue)
          .subscribe(
            predictionMessage => {

              predictionMessage.resetDisplay = resetDisplay;
              this.predictionEmitter.next(predictionMessage);
            },
            error => console.error(error),
            () => this.loadingAnimation = false
          )
      );
    }
  }

  public buttonClickedInsertText() {

    this.insertSmartResponseProposal();
  }

  public async insertSmartResponseProposal() {

    const currentEmailText = await Message.getBodyText();

    if (this.compareInitialHashValueWithCurrentHashValue(this.getHashValue(currentEmailText)) || currentEmailText === '" "') {

      Office.context.mailbox.item.body.setSelectedDataAsync(
        this.currentlyDisplayedSmartResponseProposal.smartResponse,
        {coercionType: Office.CoercionType.Html}
      );

    } else {

      Office.context.mailbox.item.body.setSelectedDataAsync(
        '<br><br>' + this.currentlyDisplayedSmartResponseProposal.smartResponseText + '<br>',
        {coercionType: Office.CoercionType.Html}
      );
    }

    if (this.currentlyDisplayedSmartResponseProposal.hasAttachments) {
      this.currentlyDisplayedSmartResponseProposal.attachments
        .forEach(async attachment => {
          if (!await Message.attachmentAlreadyExist(attachment)) {
            const accessToken = this.authorizationValue.replace('Bearer ', '');
            Message.addFileAttachment(attachment, accessToken);
          }
        });
    }
  }

  private getHashValue(text: string): string {

    text = Md5.hashStr(text);
    return text;
  }

  private compareInitialHashValueWithCurrentHashValue(currentEmailText: string): boolean {

    return this.initialEmailText === currentEmailText;
  }

  restart() {
    window.location.reload();
  }
}
