import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MyUnsubscribeComponent} from '../../../../my-unsubscribe/my-unsubscribe.component';
import {from, Subject} from 'rxjs';
import {concatMap, delay, switchMap, take, takeUntil} from 'rxjs/operators';
import {PredictionConsumption, PredictionMessage} from '../../../../services/mailytica-http/PredictionMessage';

@Component({
  selector: 'app-my-typewriter-component',
  templateUrl: './my-typerwriter.component.html',
  styleUrls: ['./my-typerwriter.component.css']
})
export class MyTyperwriterComponent extends MyUnsubscribeComponent implements OnInit {

  @Input() predictionEmitter: Subject<PredictionMessage>;

  @Input() predictionConsumptionEmitter: Subject<PredictionConsumption> = new Subject<PredictionConsumption>();

  @Input() expandPredictionRequestEmitter: Subject<string> = new Subject<string>();

  typedMessage: string = '';

  cssStyle: string = '';

  messageElements: Array<Sentence | SentenceEnding> = new Array<Sentence | SentenceEnding>(Sentence.empty());

  lastPredictionWasEmpty: boolean = true;

  constructor(
    public translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {

    const resetSubject = new Subject();

    super.registerSubscription(
      this.predictionEmitter
        //        .pipe(tap(message => resetSubject.next())) // cancel execution of previously emitted value
        .subscribe(
          (predictionMessage) => {

            if (predictionMessage.resetDisplay) {

              resetSubject.next();
              this.resetMessage();
            }

            super.registerSubscription(
              resetSubject
                .pipe(take(1))
                .subscribe(
                  () => this.resetMessage(),
                  error => console.error(error)
                )
            );

            this.cssStyle = predictionMessage.cssStyle;
            this.lastPredictionWasEmpty = predictionMessage.prediction.replace('<NEW_LINE>', '').replace('<NEW_PARAGRAPH>', '').trim().length === 0;

            if (!this.lastPredictionWasEmpty) {
              const sentenceSplitter = new Set<string>(['.', ':', '!']);
              let countSentences = 1;

              super.registerSubscription(
                from(predictionMessage.prediction.replace('<NEW_LINE>', '\n').replace('<NEW_PARAGRAPH>', '\n\n'))
                  .pipe(takeUntil(resetSubject))
                  .pipe(switchMap(m => m))
                  .pipe(concatMap(character => from(character).pipe(delay(10))))
                  .pipe(takeUntil(resetSubject))
                  .subscribe(
                    character => {

                      const lastSentence: Sentence = this.messageElements.filter((sentence: Sentence) => sentence).reverse()[0] as Sentence;
                      lastSentence.add(character);

                      if (sentenceSplitter.has(character)) {

                        const sentenceEnding = new SentenceEnding(countSentences);

                        this.messageElements.push(sentenceEnding);
                        this.messageElements.push(Sentence.empty());

                        countSentences++;

                      } else {

                        this.typedMessage = this.typedMessage + character;
                      }
                    },
                    error => console.error(error)
                  )
              );
            }
          },
          (error) => console.error(error)
        )
    );
  }

  resetMessage(): void {

    this.typedMessage = '';
    this.messageElements = new Array(Sentence.empty());
  }

  loadPrediction(sentenceIndex: number): void {

    const message: string = this.messageElements
      .filter((sentence: Sentence) => Sentence)
      .map(sentence => sentence as Sentence)
      .slice(0, sentenceIndex)
      .map(sentence => sentence.sentence)
      .join(' ');

    this.predictionConsumptionEmitter.next(
      new PredictionConsumption(
        message.replace('<NEW_LINE>', '<br>').replace('<NEW_PARAGRAPH>', '<br><br>'),
        this.cssStyle
      ));
  }

  requestPrediction(sentenceIndex: number): void {

    const message: string = this.messageElements
      .filter((sentence: Sentence) => Sentence)
      .map(sentence => sentence as Sentence)
      .slice(0, sentenceIndex)
      .map(sentence => sentence.sentence)
      .join(' ');

    this.expandPredictionRequestEmitter.next(message);
  }

  isSentenceEnding(object): boolean {

    return object.constructor.name === 'SentenceEnding';
  }

  isSentence(object): boolean {

    return object.constructor.name === 'Sentence';
  }
}

export class SentenceEnding {

  constructor(
    public sentenceIndex: number
  ) {

  }
}

export class Sentence {

  constructor(
    public sentence: string
  ) {

  }

  public static empty(): Sentence {

    return new Sentence('');
  }

  public add(character: string): void {

    this.sentence = this.sentence + character;
  }
}
