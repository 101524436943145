<div mat-dialog-content>
    <div *ngFor="let data of this.requiredMetadata">
        <div class="ms-font-m field">
            {{data.metadataFieldId}}:
            <div class="ms-TextField ms-TextField--placeholder">
                <input class="ms-TextField-field" type="text" value={{data.metadataValue}} placeholder={{data.metadataValue}} id={{data.metadataFieldId}}>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <button class="ms-Button ms-Button--secundary buttonsModalFooter" (click)="close()">
        <span class="ms-Button-label">{{'CANCEL-BUTTON' | translate}}</span>
    </button>
    <button class="ms-Button ms-Button--primary buttonsModalFooter"
        (click)="checkParameter()">
        <span class="ms-Button-label">{{'SAVE-BUTTON' | translate}}</span>
    </button>
</div>