import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MessageComposeComponent } from './message-compose/message-compose.component';
import { NavigationBarComponent } from './navigationBar/navigationBar.component';

const routes: Routes = [
  { path: 'messageRead/:baseUrl/:loginUrl',  component: NavigationBarComponent},
  { path: 'messageRead/:baseUrl/:loginUrl/:whitelisting',  component: NavigationBarComponent},
  { path: 'messageCompose/:baseUrl', component: MessageComposeComponent },
  { path: '**', component: NavigationBarComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy'})],
  exports: [RouterModule] 
})
export class AppRoutingModule { 
}


