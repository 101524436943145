import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-my-unsubscribe',
  template: `NO UI TO BE FOUND HERE`,
  styleUrls: ['./my-unsubscribe.component.scss']
})
export class MyUnsubscribeComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  ngOnInit(): void { }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  registerSubscription(subscription) {
    this.subscriptions.push(subscription);
  }
}
