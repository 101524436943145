<div id="settings">
    <app-login *ngIf="!this.mailboxMailyticaAccountMapping.categoryModel.categoryModelId && !this.authorizationValueConfiguredInManifest"
    [categoryModels]="this.categoryModels"
    [selectedCategoryModel]="this.categoryModels[0]"
    [error]="this.error"
    [mailbox]="this.mailboxMailyticaAccountMapping.mailbox"
    (loginEvent)="this.login($event)"
    (submitLoginEvent)="this.submit($event)"
    ></app-login>
    
    <div *ngIf="this.mailboxMailyticaAccountMapping.categoryModel.categoryModelId || this.authorizationValueConfiguredInManifest">
        <a class="link ms-font-l" (click)="openUrl(this.loginUrl)">https://{{this.loginUrl}}</a>
        
        <div class="accountData">
            <div *ngIf="this.mailboxMailyticaAccountMapping.categoryModel.categoryModelLabel != undefined" class="ms-font-l">{{this.constants.name}}-{{'CURRENT-CATEGORYMODEL' | translate}}: 
                <div class="ms-font-m">
                    {{this.mailboxMailyticaAccountMapping.categoryModel.categoryModelLabel}}
                </div>
            </div>
            <div class="ms-font-l">{{'CURRENT-MAILBOX' | translate}}: 
                <div class="ms-font-m">
                    {{this.mailboxMailyticaAccountMapping.mailbox}}
                </div>
            </div>
        </div>
        
        <button *ngIf="!this.authorizationValueConfiguredInManifest" class="ms-Button ms-Button--primary" (click)="logout()">
            <span class="ms-Button-label">{{'LOGOUT-BUTTON' | translate}}</span>
        </button>

        <div class="ms-font-l">{{'LANGUAGE-SETTINGS' | translate}}</div>
        <div class="btn-group btn-block">
            <div class="ms-TextField">
                <button class="btn btn-default btn-block dropdown-toggle text-left" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{convertIsoCodeToLanguage(translateService.currentLang)}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a *ngFor="let language of translateService.getLangs()" class="dropdown-item" (click)="changeLanguage($event)">{{convertIsoCodeToLanguage(language)}}</a>
                </div>
            </div>
        </div>

        <div *ngIf="this.error.errorOfficeContext">
            <div class="errorHeadline">
              <i class="ms-Icon ms-Icon--ErrorBadge" aria-hidden="true"></i>
              <div class="ms-font-l errorStatus">{{'MAILBOX-NOT-AUTHORIZED'| translate}}</div>
            </div>
            <li class="ms-font-m" *ngFor="let account of this.accountWhitelisting">{{account}}</li>
        </div>
    </div>

    <div class="version">
        {{constants.name}}-Version: {{constants.versionNr}}
    </div>
</div>