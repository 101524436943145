<div class="modal fade" id="languageModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="btn-group btn-block">
            <button class="btn btn-default btn-block dropdown-toggle text-left" type="button"
              id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" #language>
              {{ this.selectedLanguage }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a *ngFor="let lang of languages" class="dropdown-item"
                (click)="this.changeSelectedLanguage(lang)">{{lang}}</a>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="ms-Button ms-Button--secundary buttonsModalFooter" data-dismiss="modal">
            <span class="ms-Button-label">{{'CANCEL-BUTTON' | translate}}</span>
          </button>
          <button class="ms-Button ms-Button--primary buttonsModalFooter" data-dismiss="modal"
            (click)="this.emitEvent()">
            <span class="ms-Button-label">{{'SAVE-BUTTON' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>