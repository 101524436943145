import { SmartResponseProposal } from "./MailyticaSmartResponse";

export class SortedSmartResponseProposals{
    constructor(public topicLabel: string, public smartResponseProposals: SmartResponseProposal[]){
    }

    public static sortSmartResponseProposals(smartResponseProposals: SmartResponseProposal[]): SortedSmartResponseProposals[]{
        let sortedSmartResponseProposals: SortedSmartResponseProposals[] = new Array();
        smartResponseProposals.forEach(smartResponseProposal => {
            let added = false;
            let tempProposal = new SortedSmartResponseProposals(smartResponseProposal.topicLabel, new Array<SmartResponseProposal>(smartResponseProposal));
    
            //find existing proposals with same topic
            sortedSmartResponseProposals.forEach(sortedProposal => {
                if(tempProposal.topicLabel == sortedProposal.topicLabel){
                    sortedProposal.smartResponseProposals.push(smartResponseProposal);
                    added = true;
                }
            });
    
            if(!added){
                sortedSmartResponseProposals.push(tempProposal);
            }
        });

        //set AnredeProposal as a dropdown item of StandardProposal
        let proposalStandard = sortedSmartResponseProposals.find(sortedSmartResponseProposal => sortedSmartResponseProposal.topicLabel == "Standard");
        let proposalAnrede = sortedSmartResponseProposals.find(sortedSmartResponseProposal => sortedSmartResponseProposal.topicLabel == "Anrede");

        if(proposalStandard && proposalAnrede){
            proposalAnrede.smartResponseProposals[0].label = "Anrede";
            proposalAnrede.smartResponseProposals[0].topicLabel = "Standard";
            proposalStandard.smartResponseProposals.push(proposalAnrede.smartResponseProposals[0]);
            sortedSmartResponseProposals.splice(sortedSmartResponseProposals.indexOf(proposalAnrede), 1);
        }

        return sortedSmartResponseProposals;
    }

}