export class PredictionMessage {

  constructor(
    public inputMessage: string,
    public languageModelId: string,
    public prediction: string,
    public cssStyle: string,
    public resetDisplay: boolean = true
  ) {
  }

  public static fromJson(predictions: any): PredictionMessage {

    const prediction = predictions[0];

    if (prediction) {

      return new PredictionMessage(
        prediction.inputMessage,
        prediction.languageModelId,
        prediction.prediction,
        prediction.cssStyle
      );

    } else {

      return new PredictionMessage('', '', '', '');
    }
  }

  public predictionHtml(): string {

    return this.prediction
      .replace('<NEW_LINE>', '<br>')
      .replace('<NEW_PARAGRAPH>', '<br><br>');
  }
}

export class PredictionConsumption {

  constructor(
    public prediction: string,
    public cssStyle: string
  ) {
  }

  composedHtml(): string {

    return '<span style="' + this.cssStyle + '">' + this.prediction + '</span>';
  }
}
