import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AvailableField, ClientSetting, MailyticaSmartResponse, SmartResponse, SmartResponseProposal } from 'src/app/services/mailytica-http/MailyticaSmartResponse';
import { AvailableFieldMetadataMapping, Metadata } from "src/app/services/mailytica-http/MetaData";

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.css']
})
export class FieldsComponent {
  @Input() availableFields: AvailableField[];
  @Input() metadata: Metadata[];
  @Input() clientSettings: ClientSetting;

  @Output() metaDataChangeEvent: EventEmitter<Metadata[]> = new EventEmitter<Metadata[]>();

  public availableFieldMetadataMapping: AvailableFieldMetadataMapping[] = new Array();

  ngOnChanges(): void {
    this.availableFieldMetadataMapping = AvailableFieldMetadataMapping.matchAvailableFieldsToMetadata(this.availableFields, this.metadata);

    if(this.clientSettings.finishMessageActivityStatus == 'active' || this.clientSettings.finishMessageBySmartResponseStatus == 'active'){
      let metadata = this.metadata.filter(metadata => metadata.metadataFieldId == "finished");
      if(metadata.length > 0){
        let availableField = new AvailableField("displayNonEditable", "finished", "Message finished", "String");
        this.availableFieldMetadataMapping.push(new AvailableFieldMetadataMapping(availableField, metadata, metadata[0].metadataValue))
      }
    }
  }

  public openUrl(availableField: string){
    this.availableFieldMetadataMapping.forEach(mapping => {
      if(mapping.metadata[0].metadataFieldId == availableField){
        let url = mapping.actValue;

        if(!url.includes("https://")){
          url = "https://" + url;
        }

        window.open(url);
      }
    })
  }

  public selectedDropDownItemChanged(availableField: AvailableField, fieldValue){
    this.availableFieldMetadataMapping.forEach(mapping => {
      if(mapping.availableField.fieldNameId == availableField.fieldNameId){
        mapping.actValue = fieldValue.target.innerText;
      }
    });

    this.metaDataChangeEvent.emit(this.metadata);
  }

  public metadataChanged(mapping: AvailableFieldMetadataMapping){
    this.metadata.forEach(metadata => {
      if(mapping.metadata[0].metadataFieldId == metadata.metadataFieldId){
        metadata.metadataValue = mapping.actValue;
      }
    })

    this.metaDataChangeEvent.emit(this.metadata);
  }
}
