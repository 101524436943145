<div id="userActions"
     *ngFor="let userAction of availableUserActions"
>
  <button class="ms-Button ms-Button--primary"
          (click)="this.checkUserActionParameter(userAction)"
          data-toggle="modal"
          data-target="#user-actions-modal"
  >
    <span class="ms-Button-label">{{userAction.actionLabel}}</span>
  </button>

  <app-metadata-modal *ngIf="this.showModal"
                      [requiredMetadata]="userAction.getRequiredMetadata(this.metaData)"
                      (sendUserActionRequest)="this.sendRequest($event, userAction)"
  ></app-metadata-modal>

</div>
