import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-internal-note',
  templateUrl: './internal-note.component.html',
  styleUrls: ['./internal-note.component.css']
})
export class InternalNoteComponent implements OnInit {
  @Input() internalNote: string;

  constructor() { }

  ngOnInit(): void {
  }

  openLink(text: string){
    if(text.includes("http")){
      let hyperlinkStartIndex = text.indexOf("http") - 1;
      let hyperlinkEndIndex = text.indexOf(" ", text.indexOf("http"));

      //if no whitespace after hyperlink exist --> hyperlinkEndIndex = end of the text
      if(hyperlinkEndIndex == -1){
        hyperlinkEndIndex = text.length;
      }
      
      let url = text.substring(hyperlinkStartIndex, hyperlinkEndIndex);
      
      window.open(url);
    }
  }

}
