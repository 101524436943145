// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

// routing
export const routeMailyticaSmartResponseGUI = 'mailytica-SmartResponseGUI';
export const routeSettings = 'login';

// TranslateService
export const languages = ['German', 'English', 'French', 'Italian', 'Czech', 'Spanish', 'Dutch'];

export const versionNr = 'v1.58.9-SNAPSHOT-SA';
export const name = 'Mailytica';
export const smartAssist = true;

