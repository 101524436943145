import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { LoginRequest } from 'src/app/services/mailytica-http/LoginCredentials';
import { CategoryModel } from 'src/app/services/mailytica-http/AvailableScopesHeader';
import { Error } from './error';
import * as constants from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnChanges {
  @Input() categoryModels: Array<CategoryModel>;
  @Input() selectedCategoryModel: CategoryModel = new CategoryModel('', '');
  @Input() error: Error;
  @Input() mailbox: string;

  @Output() loginEvent: EventEmitter<LoginRequest> = new EventEmitter<LoginRequest>();
  @Output() submitLoginEvent: EventEmitter<CategoryModel> = new EventEmitter<CategoryModel>();

  public loadingAnimation: boolean = false;
  public loginEventSend: boolean = false;

  public loginRequest: LoginRequest = new LoginRequest();
  public constants = constants;

  constructor() {
  }

  ngOnInit(): void {
    this.loginRequest.password = '';
    this.loginRequest.username = '';
  }

  ngOnChanges(): void {
    this.loginRequest.password = '';
    this.loginRequest.username = '';
    this.loginEventSend = false;
  }

  clickLoginEvent(){
    this.loginEvent.emit(this.loginRequest);
    this.loginEventSend = true;
  }

  clickSubmitEvent(){
    this.submitLoginEvent.emit(this.selectedCategoryModel);

    this.loginRequest.password = '';
    this.loginRequest.username = '';
  }

  updateSelectedCategoryModel(event){
    this.selectedCategoryModel = event;
  }
}
