import {Injectable} from '@angular/core';
import {CategoryModel} from '../mailytica-http/AvailableScopesHeader';
import {Error} from 'src/app/mailytica/settings/login/error';

@Injectable({
  providedIn: 'root'
})
export class OfficeRoamingSettingsService {
  private officeRoamingSettings: Office.RoamingSettings;

  constructor() {
    this.officeRoamingSettings = Office.context.roamingSettings;
    this.initializeSettings();
  }

  private async initializeSettings() {
    if (this.getLanguage() === undefined) {
      this.setLanguage(Office.context.displayLanguage.slice(0, 2));
    }
  }

  public async setLanguage(value: string) {
    this.officeRoamingSettings.set('language', value);
    await this.saveSettings();
  }

  public async setCategoryModel(mailbox: string, value: CategoryModel) {
    this.officeRoamingSettings.set(mailbox + 'CategoryModelId', value.categoryModelId);
    this.officeRoamingSettings.set(mailbox + 'CategoryModelLabel', value.categoryModelLabel);

    await this.saveSettings();
  }

  public async setMailboxAuthorizationValueMapping(mailbox: string, authorizationValue: string) {
    this.officeRoamingSettings.set(mailbox, authorizationValue);
    await this.saveSettings();
  }

  public async removeMailboxMailyticaAccountMapping(mailbox: string) {
    this.officeRoamingSettings.remove(mailbox);
    this.officeRoamingSettings.remove(mailbox + 'CategoryModelId');
    this.officeRoamingSettings.remove(mailbox + 'CategoryModelLabel');
    await this.saveSettings();
  }

  public getLanguage(): string {
    return this.officeRoamingSettings.get('language');
  }

  public getCategoryModel(mailbox: string): CategoryModel {
    return new CategoryModel(
      this.officeRoamingSettings.get(mailbox + 'CategoryModelId'),
      this.officeRoamingSettings.get(mailbox + 'CategoryModelLabel')
    );
  }

  public getMailboxAuthorizationValueMapping(mailbox: string): string {

    const authorizationValue = this.officeRoamingSettings.get(mailbox);

    return authorizationValue;
  }

  private saveSettings(): Promise<boolean> {
    return new Promise((resolve) => {
      let success = false;
      this.officeRoamingSettings.saveAsync(response => {
        if (response.status === Office.AsyncResultStatus.Succeeded) {
          success = true;
        } else {
          const error = new Error();
          error.errorOfficeContext = 'settings couldn not be saved';
          throw error;
        }
        resolve(success);
      });
    });
  }
}
