import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Metadata} from 'src/app/services/mailytica-http/MetaData';
import {OfficeRoamingSettingsService} from 'src/app/services/office-roaming-settings/office-roaming-settings.service';

@Component({
  selector: 'app-metadata-modal',
  templateUrl: './metadata-modal.component.html',
  styleUrls: ['./metadata-modal.component.css'],
})
export class MetadataModalComponent implements OnInit {
  @Input() requiredMetadata: Metadata[];
  @Output() sendUserActionRequest: EventEmitter<Metadata[]> = new EventEmitter<Metadata[]>();

  // dismissModal = true;

  constructor(
    public dialogRef: MatDialogRef<MetadataModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private officeService: OfficeRoamingSettingsService
  ) {
    this.requiredMetadata = this.data.requiredMetadata;

    translateService.addLangs(['en', 'de']);
    translateService.setDefaultLang('de');
    translateService.use(officeService.getLanguage());
  }

  ngOnInit(): void {
  }

  checkParameter() {

    let sendRequest = true;
    const metadata: Metadata[] = [];

    this.requiredMetadata.forEach(parameter => {

      const element = <HTMLInputElement> document.getElementById(parameter.metadataFieldId);

      if (element.value !== '') {
        parameter.metadataValue = element.value;
      } else {
        sendRequest = false;
      }

      metadata.push(parameter);
    });

    if (sendRequest) {
      this.dialogRef.close(metadata);
      // this.sendUserActionRequest.emit(metadata);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
