import {AvailableField} from "./MailyticaSmartResponse";

export class Metadata {
  constructor(
    public metadataFieldId: string,
    public metadataFieldLabel: string,
    public metadataType: string,
    public metadataValue: string) {
  }

  public toJson(): any {

    const json = {
      metadataFieldId: this.metadataFieldId,
      metadataFieldLabel: this.metadataFieldLabel,
      metadataType: this.metadataType,
      metadataValue: this.metadataValue
    };

    return json;
  }

  public static fromJson(entity: any): Metadata {

    return new Metadata(
      entity.metadataFieldId,
      entity.metadataFieldLabel,
      entity.metadataType,
      entity.metadataValue
    )
  }
}

export class AvailableFieldMetadataMapping {

  constructor(public availableField: AvailableField, public metadata: Metadata[], public actValue: string) {
  }

  public static matchAvailableFieldsToMetadata(availableFields: AvailableField[], metadata: Metadata[]): AvailableFieldMetadataMapping[] {
    let availableFieldMetadataMappings: AvailableFieldMetadataMapping[] = new Array();

    availableFields.forEach(field => {
      metadata.forEach(data => {
        if (data.metadataFieldId == field.fieldNameId) {
          //push DropDown-Fields only once to the availableFieldMetadataMappings-Array
          if (field.fieldType == "StringArray") {
            let duplicate: boolean = false;

            availableFieldMetadataMappings.forEach(mapping => {
              if (mapping.availableField == field) {
                duplicate = true;
                mapping.metadata.push(data);
              }
            })

            if (!duplicate) {
              let tempMetadata: Metadata[] = new Array();
              tempMetadata.push(data);
              availableFieldMetadataMappings.push(new AvailableFieldMetadataMapping(field, tempMetadata, data.metadataValue));
            }
          } else if (field.fieldType != "StringArray") {
            //ignore displayNonEditable-mappings without metadataValue
            if (!(data.metadataValue == '' && field.displayInClientStatus == 'displayNonEditable')) {
              //if fieldLabel is unset use fieldID
              if (field.fieldNameLabel == '') {
                field.fieldNameLabel = field.fieldNameId;
              }

              let tempMetadata: Metadata[] = new Array();
              tempMetadata.push(data);
              availableFieldMetadataMappings.push(new AvailableFieldMetadataMapping(field, tempMetadata, data.metadataValue.replace("\\r", "")));
            }
          }
        }
      })
    });

    return availableFieldMetadataMappings;
  }

}
