import {Attachment} from './MailyticaSmartResponse';
import {Metadata} from './MetaData';

export class Message {
  constructor(
    public messageId: string,
    public subject: string,
    public sender: string,
    public recipients: string[],
    public message: string,
    public send: Date,
    public metadataFields: Metadata[]
  ) {
  }

  public static async getMessage(): Promise<Message> {
    return new Promise((resolve) => {
      Office.onReady(async () => {
        try {
          const recipients = Office.context.mailbox.item.to.map(email => email.emailAddress);
          const sender = Office.context.mailbox.item.from.displayName + ' <' + Office.context.mailbox.item.from.emailAddress + '>';
          const messageId = Office.context.mailbox.item.internetMessageId;
          const subject = Office.context.mailbox.item.subject;
          const send = Office.context.mailbox.item.dateTimeModified;
          const currentUserName = Office.context.mailbox.userProfile.displayName;
          const currentUserEmail = Office.context.mailbox.userProfile.emailAddress;
          const messageText = await this.getBodyText();
          //messageText = messageText.slice(1, messageText.length - 1);
          //const messageHtml = await this.getBodyHtmlText();

          const metadatafields = new Array<Metadata>();
          //metadatafields.push(new Metadata("htmlBody", "htmlBody", "String", messageHtml));
          metadatafields.push(new Metadata('currentUserName', 'currentUserName', 'String', currentUserName));
          metadatafields.push(new Metadata('currentUserEmail', 'currentUserEmail', 'String', currentUserEmail));

          const attachments: Office.AttachmentDetails[] = Office.context.mailbox.item.attachments.map(attachment => attachment);

          if (attachments.length > 0) {
            attachments.map(async attachment => {
              const base64Value = await this.getMessageAttachment(attachment.id);
              metadatafields.push(new Metadata(attachment.name, attachment.name, 'base64', base64Value));
            });
          }

          resolve(new Message(messageId, subject, sender, recipients, messageText, send, metadatafields));

        } catch (e) {
          console.error(e);
        }
      });
    });
  }

  public static async getDraft(id: string): Promise<Message> {

    return new Promise((resolve) => {
      Office.onReady(async () => {

        //recipients and sender are swapped by classifyDraft
        const recipients = [];
        const recipientsMime = await this.getSender();
        recipients.push(recipientsMime.displayName + ' <' + recipientsMime.emailAddress + '>');
        const sender = (await this.getRecipients()).map(recipient => recipient.emailAddress)[0];
        const messageId = id;
        const subject = '';
        const send = new Date();
        const messageText = '';

        const message = new Message(messageId, subject, sender, recipients, messageText, send, []);

        resolve(message);
      });
    });
  }

  public static async getRecipients(): Promise<Office.EmailAddressDetails[]> {
    return new Promise((resolve) => {
      Office.onReady(async () => {
        try {
          Office.context.mailbox.item.to.getAsync(recipients => {
            resolve(recipients.value);
          });
        } catch (error) {
          console.log(error);
        }
      });
    });
  }

  private static async getSender(): Promise<Office.EmailAddressDetails> {
    return new Promise((resolve) => {
      Office.context.mailbox.item.from.getAsync(sender => {
        resolve(sender.value);
      });
    });
  }

  private static async getSubject(): Promise<any> {
    return new Promise((resolve) => {
      Office.context.mailbox.item.subject.getAsync(subject => {
        resolve(subject.value);
      });
    });
  }

  public static async getBodyText(): Promise<string> {

    return new Promise((resolve) => {
      Office.onReady(async () => {
        try {
          Office.context.mailbox.item.body.getAsync(Office.CoercionType.Text, text => {
            resolve(text.value);
          });
        } catch {
          resolve('');
        }
      });
    });
  }

  private static async getBodyHtmlText(): Promise<string> {

    return new Promise((resolve) => {
      Office.context.mailbox.item.body.getAsync(Office.CoercionType.Html, html => {
        resolve(html.value);
      });
    });
  }

  public static async getMessageAttachment(attachmentId: string): Promise<any> {

    return new Promise((resolve) => {
      Office.context.mailbox.item.getAttachmentContentAsync(attachmentId, attachment => {
        if (attachment.value.format === 'base64') {
          resolve(attachment.value.content);
        }
      });
    });
  }

  private static async getAttachments(): Promise<Office.AttachmentDetailsCompose[]> {
    return new Promise((resolve) => {
      Office.context.mailbox.item.getAttachmentsAsync(attachments => {
        resolve(attachments.value);
      });
    });
  }

  public static async addFileAttachment(attachment: Attachment, accessToken: string) {

    Office.onReady(async () => {
      Office.context.mailbox.item.addFileAttachmentAsync(attachment.attachmentApiUrl + accessToken, attachment.filename);
    });
  }

  public static async attachmentAlreadyExist(newAttachment: Attachment): Promise<boolean> {

    const attachments: Office.AttachmentDetailsCompose[] = await this.getAttachments();
    let officeAttachment: Office.AttachmentDetailsCompose;

    if (attachments.length > 0) {
      officeAttachment = attachments.find(attachment => attachment.name === newAttachment.filename);
    }

    if (officeAttachment) {
      return true;
    } else {
      return false;
    }
  }

  public fallbackMessagetoJson(): any {

    const json = {
      messageId: this.messageId,
      subject: this.subject,
      sender: this.sender,
      recipients: this.recipients,
      message: this.message,
      send: this.send.toDateString(),
      metadataFields: this.metadataFields.map(field => field.toJson()),
      sendAttachments: false
    };

    return json;
  }

  public toJson(): any {

    const json = {
      internetMessageId: this.messageId,
      metadataFields: this.metadataFields.map(field => field.toJson())
    };

    return json;
  }
}


