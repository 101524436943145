<div class="sectionProposal" [style.maxHeight.vh]="this.styleMaxHeight"
     *ngIf="this.smartResponseProposal.smartResponse != null">

  <div>

    <div *ngIf="this.smartResponseProposal.topicLabel != 'Smart Assist'"
         class="ms-font-m"
         style="margin-bottom: 15px; font-weight: bold;"
    >
      {{ this.smartResponseProposal.label }}:
    </div>

    <div *ngIf="this.smartResponseProposal.topicLabel != 'Smart Assist'"
         class="ms-font-m textGradientLocked proposal"
         [innerHTML]="this.smartResponseProposal.smartResponse">
    </div>

    <div *ngIf="this.smartResponseProposal.topicLabel === 'Smart Assist'"
         class="ms-font-m changed proposal">

      <app-my-typewriter-component
        [predictionEmitter]="this.predictionEmitter"
        [predictionConsumptionEmitter]="this.predictionConsumptionEmitter"
        [expandPredictionRequestEmitter]="this.expandPredictionRequestEmitter"
      ></app-my-typewriter-component>

    </div>

  </div>
</div>
