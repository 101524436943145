<div *ngIf="this.userHasNoLogin">
  <div class="errorHeadline">
    <i class="ms-Icon ms-Icon--ErrorBadge" aria-hidden="true"></i>
    <div class="ms-font-l errorStatus">{{'MAILBOX-NOT-AUTHORIZED'| translate}}</div>
  </div>
  <li class="ms-font-m" *ngFor="let account of this.accountWhitelisting">{{account}}</li>
</div>

<div *ngIf="!this.userHasNoLogin" class="content">
  <div class="ms-Pivot" >
    <ul>
      <li class="ms-Pivot-link  {{ this.isSmartResponseSelectedClass() }}" (click)="changeSelectedTab('smartResponse')" tabindex="1">{{this.name}}</li>
      <li class="ms-Pivot-link {{ this.isSettingsSelectedClass() }}" (click)="changeSelectedTab('settings')" tabindex="2">{{'SETTINGS-TAB' | translate}}</li>
    </ul>
  </div>

  <app-mailytica-SmartResponseGUI *ngIf="this.selectedTab === 'smartResponse'"
    [mailyticaAPIUrl]="this.mailyticaAPIUrl"
    [mailboxMailyticaAccountMapping]="this.mailboxMailyticaAccountMapping"
    [authorizationValue]="this.authorizationValue"
    [authorizationValueConfiguredInManifest]="this.authorizationValueConfiguredInManifest">
  </app-mailytica-SmartResponseGUI>

  <app-settings *ngIf="this.selectedTab === 'settings'"
    [mailboxMailyticaAccountMapping]="this.mailboxMailyticaAccountMapping"
    [mailyticaAPIUrl]="this.mailyticaAPIUrl"
    [loginUrl]="this.loginUrl"
    [accountWhitelisting]="this.accountWhitelisting"
    [authorizationValueConfiguredInManifest]="this.authorizationValueConfiguredInManifest"
    (submitLoginEvent)="submitLogin($event)"
    (logoutEvent)="logout()">
  </app-settings>

  <div class="userInfo" *ngIf="this.selectedTab === 'restartOutlook'">
    <i class="infoIcon ms-Icon ms-Icon--Info" aria-hidden="true"></i>
    <div class="ms-font-m">{{'RESTART-OUTLOOK'| translate}}</div>
  </div>
</div>


