import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
//import * as Office from 'node_modules/@types/office-js';

if (environment.production) {
  enableProdMode();
}

Office.onReady(async() => {
  console.log("office initialized")
  platformBrowserDynamic().bootstrapModule(AppModule).catch(error => console.error(error));
})