
<div class="ms-font-l reloadIcon">
    {{'TOPIC' | translate}}
    
    <mat-icon (click)="this.clickReloadIcon()">sync</mat-icon>
</div>

<!-- [(ngModel)] leads to infinite loop! -->
<ng-select2 class="searchDropDown" [data]="topics" (valueChanged)="this.changeSelectedTopicEvent($event)" [value]="this.classificationLabel"></ng-select2>

