export class AvailableScopesHeader {
    constructor(public categoryModels: CategoryModel[]){
    }

    public static fromJson(entity: any): AvailableScopesHeader {
        return new AvailableScopesHeader(
            entity.map(categoryModel => CategoryModel.fromJson(categoryModel))
        )
    }
}

export class CategoryModel {
    constructor(
        public categoryModelId: string, 
        public categoryModelLabel: string){
    }

    public static fromJson(entity: any): CategoryModel {
        return new CategoryModel(
            entity.categoryModelId,
            entity.categoryModelLabel
        )
    }
}
