import {Component, Input, OnInit} from '@angular/core';
import {SmartResponseProposal} from 'src/app/services/mailytica-http/MailyticaSmartResponse';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {PredictionConsumption, PredictionMessage} from '../../../../services/mailytica-http/PredictionMessage';

@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.css']
})
export class ProposalComponent implements OnInit {

  @Input() smartResponseProposal: SmartResponseProposal;
  @Input() styleMaxHeight: string;

  @Input() predictionEmitter: Subject<PredictionMessage>;
  @Input() predictionConsumptionEmitter: Subject<PredictionConsumption> = new Subject<PredictionConsumption>();
  @Input() expandPredictionRequestEmitter: Subject<string> = new Subject<string>();

  constructor(
    public translateService: TranslateService
  ) {
  }

  ngOnInit(): void {

  }
}
