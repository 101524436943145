import {Metadata} from './MetaData';
import * as constants from 'src/environments/environment';

export class MailyticaSmartResponse {
  constructor(
    public topics: string[],
    public availableSmartResponses: AvailableSmartResponses,
    public smartResponseProposals: SmartResponseProposal[],
    public availableFields: AvailableField[],
    public language: string,
    public messageId: string,
    public metadata: Metadata[],
    public classification: Classification,
    public clientSettings: ClientSetting,
    public availableUserActions: AvailableUserAction[],
    public isLocked: boolean = false) {
  }

  public static fromJson(entity: any): MailyticaSmartResponse {

    const topics: string[] = entity.topics;

    if (!topics.includes('Standard')) {
      topics.push('Standard');
    }

    return new MailyticaSmartResponse(
      topics,
      entity.availableSmartResponses,
      entity.smartResponseProposals.map(smartResponseProposal => SmartResponseProposal.fromJson(smartResponseProposal)),
      entity.availableFields.map(availableField => AvailableField.fromJson(availableField)),
      entity.language,
      entity.messageId,
      entity.metadata.map(metadata => Metadata.fromJson(metadata)),
      entity.classification,
      entity.clientSettings,
      entity.availableUserActions.map(availableUserAction => AvailableUserAction.fromJson(availableUserAction)),
      entity.isLocked
    );
  }

  public addSmartAssistProposal() {
    if (constants.smartAssist) {
      this.smartResponseProposals.push(new SmartResponseProposal([], '', '', '', false, '', '', '', '', '', '', '', '', '', 'Smart Assist'));
    }
  }

  public setSmartAssistPredictionMessage(predictionMessage: string) {

    if (constants.smartAssist) {

      this.smartResponseProposals.find(proposal => proposal.topicLabel === 'Smart Assist').smartResponse = predictionMessage;
    }
  }
}

export class AvailableSmartResponses {
  constructor(
    public language: string,
    public topics: Topic[]) {
  }

  public static fromJson(entity: any): AvailableSmartResponses {

    return new AvailableSmartResponses(
      entity.language,
      entity.topics.map(topic => Topic.fromJson(topic))
    );
  }
}

export class Topic {
  constructor(
    public children: Child[],
    public id: string,
    public label: string,
    public smartResponses: SmartResponse[]) {
  }

  public static fromJson(entity: any): Topic {
    return new Topic(
      entity.children.map(child => Child.fromJson(child)),
      entity.id,
      entity.label,
      entity.smartResponses.map(smartResponse => SmartResponse.fromJson(smartResponse))
    );
  }
}

export class Child {
  constructor(
    public children: any[],
    public id: string,
    public label: string,
    public smartResponses: SmartResponse[]) {
  }

  public static fromJson(entity: any): Child {
    return new Child(
      entity.children,
      entity.id, entity.label,
      entity.smartResponses.map(smartResponse => SmartResponse.fromJson(smartResponse))
    );
  }
}

export class SmartResponse {
  constructor(
    public id: string,
    public internalNotice: string,
    public label: string,
    public text: string) {
  }

  public static fromJson(entity: any): SmartResponse {
    return new SmartResponse(
      entity.id,
      entity.internalNotice,
      entity.label,
      entity.text
    );
  }
}

export class SmartResponseProposal {
  constructor(
    public attachments: Attachment[],
    public autoReplyStatus: string,
    public bccRecipient: string,
    public ccRecipient: string,
    public hasAttachments: boolean,
    public id: string,
    public label: string,
    public note: string,
    public receivingAddress: string,
    public salutation: string,
    public salutationStatus: string,
    public smartResponse: string,
    public smartResponseText: string,
    public subject: string,
    public topicLabel: string,
  ) {
  }

  public static fromJson(entity: any): SmartResponseProposal {
    return new SmartResponseProposal(
      entity.attachments.map(attachment => Attachment.fromJson(attachment)),
      entity.autoReplyStatus,
      entity.bccRecipient,
      entity.ccRecipient,
      entity.hasAttachments,
      entity.id,
      entity.label,
      entity.note,
      entity.receivingAddress,
      entity.salutation,
      entity.salutationStatus,
      entity.smartResponse,
      entity.smartResponseText,
      entity.subject,
      entity.topicLabel
    );
  }

  public checkProposalTextForMetadataVariables(availableMetadata: Metadata[]): Metadata[] {

    const metadataList: Metadata[] = [];

    for (let i = 0; i < this.smartResponse.length - 1; i++) {

      if (this.smartResponse.charAt(i) === '{' && this.smartResponse.includes('}')) {

        const variable = this.smartResponse.substring(i + 1, this.smartResponse.indexOf('}', i));
        const metadata = availableMetadata.find(md => md.metadataFieldId === variable);

        if (metadata !== undefined) {
          metadataList.push(metadata);
        } else {
          metadataList.push(new Metadata(variable, variable, 'String', ''));
        }
      }
    }

    return metadataList;
  }

  public replaceProposalVariables(metadataVariables: Metadata[]): string {
    let newProposalText = this.smartResponse;

    metadataVariables.forEach(variable => {
      newProposalText = newProposalText.replace('{' + variable.metadataFieldId + '}', variable.metadataValue);
    });

    return newProposalText;
  }
}

export class Attachment {
  constructor(
    public attachmentApiUrl: string,
    public filename: string
  ) {
  }

  public static fromJson(entity: any): Attachment {

    return new Attachment(
      entity.attachmentApiUrl,
      entity.filename
    );
  }
}

export class AvailableField {
  constructor(
    public displayInClientStatus: string,
    public fieldNameId: string,
    public fieldNameLabel: string,
    public fieldType: string
  ) {
  }

  public static fromJson(entity: any): AvailableField {

    return new AvailableField(
      entity.displayInClientStatus,
      entity.fieldNameId,
      entity.fieldNameLabel,
      entity.fieldType
    );
  }
}

export class Classification {
  constructor(
    public confidence: number,
    public label: string,
    public topicId: string
  ) {
  }

  public static fromJson(entity: any): Classification {
    return new Classification(
      entity.confidence,
      entity.label,
      entity.topicId
    );
  }
}

export class ClientSetting {
  constructor(
    public displayEmptyTopicsStatus: string,
    public finishMessageActivityStatus: string,
    public finishMessageBySmartResponseStatus: string,
    public languageModelPredictionStatus: string,
    public syncCategoriesStatus: string,
    public transferRawMessageStatus: string
  ) {
  }

  public static fromJson(entity: any): ClientSetting {

    return new ClientSetting(
      entity.displayEmptyTopicsStatus,
      entity.finishMessageActivityStatus,
      entity.finishMessagesBySmartResponseStatus,
      entity.languageModelPredictionStatus,
      entity.syncCategoriesStatus,
      entity.transferRawMessageStatus
    );
  }
}

export class AvailableUserAction {

  constructor(
    public actionId: string,
    public actionLabel: string,
    public actionInputFields: string[]
  ) {
  }

  public static fromJson(entity: any): AvailableUserAction {

    return new AvailableUserAction(
      entity.actionId,
      entity.actionLabel,
      entity.actionInputFields
    );
  }

  public allNecessaryMetadataSet(availableMetadata: Metadata[]): boolean {

    let unsetParameter = false;

    // if min. metadataValue is empty --> pop-up has to be opened
    this.getRequiredMetadata(availableMetadata).forEach(metadata => {
      if (metadata.metadataValue === '') {
        unsetParameter = true;
      }
    });

    return unsetParameter;
  }

  public getRequiredMetadata(availableMetadata: Metadata[]): Metadata[] {

    const requiredMetadata: Metadata[] = [];

    this.actionInputFields.forEach(actionInputField => {

      requiredMetadata.push(new Metadata(actionInputField, '', '', ''));

      availableMetadata.forEach(metadata => {

        // replace element in array, if matching metadata exists
        if (actionInputField === metadata.metadataFieldId) {
          requiredMetadata[requiredMetadata.length - 1] = metadata;
        }
      });
    });

    return requiredMetadata;
  }
}

