import {Component, NgZone, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {OfficeRoamingSettingsService} from 'src/app/services/office-roaming-settings/office-roaming-settings.service';
import * as constants from 'src/environments/environment';
import {MailboxMailyticaAccountMapping} from '../services/office-roaming-settings/MailboxMailyticaAccountMapping';
import {AccountService} from '../services/account/account.service';
import {ActivatedRoute} from '@angular/router';
import {OfficeItemChangedService} from '../services/office-item-changed/office-item-changed.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigationBar.component.html',
  styleUrls: ['./navigationBar.component.css']
})
export class NavigationBarComponent implements OnInit {

  public queryParams: any;
  public mailyticaAPIUrl = 'api.mailytica.ai';
  public loginUrl = 'mailytica.ai';
  public accountWhitelisting: string[] = new Array();
  public authorizationValue = '';

  public mailboxMailyticaAccountMapping: MailboxMailyticaAccountMapping;
  public authorizationValueConfiguredInManifest = false;
  public userHasNoLogin = true;

  public selectedTab: string;
  public name: string = constants.name;

  constructor(
    public translate: TranslateService,
    private officeRoamingSettingsService: OfficeRoamingSettingsService,
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private zone: NgZone,
    // officeItemChangedService is necessary here, otherwise officeItem handler wouldn't be added
    private officeItemChangedService: OfficeItemChangedService
  ) {
  }

  ngOnInit(): void {

    this.translate.addLangs(['en', 'de']);
    this.translate.use(this.officeRoamingSettingsService.getLanguage());

    this.initialize();
  }

  private async initialize() {

    // get current mailbox mapping with mailyticaAccount if exists
    const mailbox = await MailboxMailyticaAccountMapping.getCurrentMailbox();
    const categoryModel = this.officeRoamingSettingsService.getCategoryModel(mailbox);
    this.mailboxMailyticaAccountMapping = new MailboxMailyticaAccountMapping(mailbox, categoryModel);

    // update mailboxMailyticaAccountMapping and authorizationValue if emailItem is switched
    OfficeItemChangedService.mailItem.subscribe(mailboxMailyticaAccountMapping => {
      this.zone.run(async () => {
        this.mailboxMailyticaAccountMapping = mailboxMailyticaAccountMapping;

        if (this.queryParams.accessToken != null) {

          this.authorizationValue = 'Bearer ' + this.queryParams.accessToken;
          this.authorizationValueConfiguredInManifest = true;

        } else {

          this.authorizationValue = this.officeRoamingSettingsService.getMailboxAuthorizationValueMapping(mailboxMailyticaAccountMapping.mailbox);
          this.authorizationValueConfiguredInManifest = false;
        }

        this.setInitialTab();
      });
    });

    // get customer configurations from manifest
    this.activatedRoute.paramMap
      .subscribe(paramMap => {

        if (paramMap.get('baseUrl')) {
          this.mailyticaAPIUrl = paramMap.get('baseUrl');
        }

        if (paramMap.get('loginUrl')) {
          this.loginUrl = paramMap.get('loginUrl');
        }

        if (paramMap.get('whitelisting')) {
          this.accountWhitelisting = paramMap.get('whitelisting').split(',');
        }
      });

    // get optional configuration from manifest
    this.activatedRoute.queryParams
      .subscribe(paramMap => {

        this.queryParams = paramMap;

        // authorizationValue is given in manifest
        if (paramMap.accessToken != null) {

          this.authorizationValue = 'Bearer ' + paramMap.accessToken;
          this.authorizationValueConfiguredInManifest = true;

        } else { // try to get authorizationValue from officeRoamingSettingsService, otherwise user is not logged in

          this.authorizationValue = this.officeRoamingSettingsService.getMailboxAuthorizationValueMapping(mailbox);
          this.authorizationValueConfiguredInManifest = false;
        }
      });

    this.setInitialTab();
  }

  private async setInitialTab() {

    const currentProfileEmailAddress: string = (await MailboxMailyticaAccountMapping.getCurrentEmailAdress()).toLowerCase();

    // check if current user is whitelisted
    if (this.accountWhitelisting.includes(currentProfileEmailAddress) || this.accountWhitelisting.length === 0) {

      this.userHasNoLogin = false;

      // user is not logged in and no authorizationValue is given in manifest --> show login page
      if (!this.mailboxMailyticaAccountMapping.categoryModel.categoryModelId && !this.authorizationValue) {
        this.selectedTab = 'settings';
      }

      // user is logged in or authorizationValue is given in manifest--> show login page
      if (this.mailboxMailyticaAccountMapping.categoryModel.categoryModelId || this.authorizationValue) {
        this.selectedTab = 'smartResponse';
      }
    } else {
      this.userHasNoLogin = true;
    }
  }

  public async submitLogin(submitResponse: any) {

    try {

      this.mailboxMailyticaAccountMapping = new MailboxMailyticaAccountMapping(
        this.mailboxMailyticaAccountMapping.mailbox,
        submitResponse.categoryModel
      );

      this.authorizationValue = 'Bearer ' + submitResponse.authorizationValue;
      this.selectedTab = 'restartOutlook';

    } catch (error) {
      throw error;
    }
  }

  public logout() {
    this.accountService.logout(this.mailboxMailyticaAccountMapping.mailbox);
    this.mailboxMailyticaAccountMapping.categoryModel.categoryModelId = undefined;
    this.mailboxMailyticaAccountMapping.categoryModel.categoryModelLabel = undefined;
    this.authorizationValue = undefined;
    this.selectedTab = 'restartOutlook';
  }

  public isSettingsSelectedClass(): string {

    // for navigationBar styling
    if (this.selectedTab === 'settings') {
      return 'is-selected';
    } else {
      return '';
    }
  }

  public isSmartResponseSelectedClass(): string {

    // for navigationBar styling
    if (this.selectedTab === 'smartResponse') {
      return 'is-selected';
    } else {
      return '';
    }
  }

  public changeSelectedTab(tab: string) {
    this.selectedTab = tab;
  }
}
