import { CategoryModel } from "../mailytica-http/AvailableScopesHeader";

export class MailboxMailyticaAccountMapping{
    constructor(
      public mailbox: string,
      public categoryModel: CategoryModel
    ){
    }

    public static async getCurrentMailbox(): Promise<string>{
      let mailbox: string;

      return new Promise(async (resolve) => {
        try{
          let sharedPropertiesAsync = Office.context.mailbox.item.getSharedPropertiesAsync;
          //sharedProperties are not available
          if(!sharedPropertiesAsync){
            try{
              //if email compose --> use sender email address
              Office.context.mailbox.item.from.getAsync(sender => {
                resolve(sender.value.emailAddress);
              })
            } catch {
              //private mailbox --> use userProfile email address
              mailbox = Office.context.mailbox.userProfile.emailAddress;
              resolve(Office.context.mailbox.userProfile.emailAddress);
            }
          }
          //sharedProperties are available
          else{
            Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, function(result) {
              if (result.status === Office.AsyncResultStatus.Succeeded && result.value !== "") {
                Office.context.mailbox.item.getSharedPropertiesAsync(
                  {
                    // Pass auth token along.
                    asyncContext: result.value
                  },
                  function(result2) {
                    let sharedProperties = result2.value;
                    mailbox = sharedProperties.targetMailbox;
                    resolve(sharedProperties.targetMailbox);
                  },
                );
              }
            });
          }
        } catch {
          await new Promise(f => setTimeout(f, 300));
          this.getCurrentMailbox();
        }
      })
    }

    public static async getCurrentEmailAdress(): Promise<string>{
      let emailAdress: string;
      
      return new Promise(async (resolve) => {
        try{
          let sharedPropertiesAsync = Office.context.mailbox.item.getSharedPropertiesAsync;
          if(!sharedPropertiesAsync){
            emailAdress = Office.context.mailbox.userProfile.emailAddress;
            resolve(emailAdress);
          }
          else{
            Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, function(result) {
              if (result.status === Office.AsyncResultStatus.Succeeded && result.value !== "") {
                Office.context.mailbox.item.getSharedPropertiesAsync(
                  {
                    // Pass auth token along.
                    asyncContext: result.value
                  },
                  function(result2) {
                    let sharedProperties = result2.value;
                    emailAdress = sharedProperties.targetMailbox;
                    resolve(sharedProperties.targetMailbox);
                  },
                );
              }
            });
          }
        } catch {
          await new Promise(f => setTimeout(f, 300));
          this.getCurrentMailbox();
        }
      })
    }
    
}